<template>
  <div id="main-wrapper">
    <div class="header dashboard">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <nav class="navbar navbar-expand-lg navbar-light px-0">
              <a class="navbar-brand" href="/home"
                ><img src="images/w_logo.png" alt=""
              /></a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      style="
                        color: white !important;
                        opacity: 1 !important;
                        font-size: x-large;
                      "
                      class="nav-link"
                      href="/home"
                      >Kirpi Yazılım</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dashboard_log my-2">
                <div class="d-flex align-items-center">
                  <div class="account_money">
                    <ul>
                      <li class="crypto">
                        <span
                          ><span v-if="GenelDurum !== null" class="miktar2">{{
                            GenelDurum?.guncelTL
                          }}</span>
                          TL</span
                        >
                      </li>
                      <li class="usd">
                        <span
                          ><span v-if="GenelDurum !== null" class="miktar2">{{
                            GenelDurum?.guncelEURO
                          }}</span>
                          EURO</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="profile_log dropdown">
                    <div class="user" data-toggle="dropdown">
                      <span class="thumb"><i class="la la-user"></i></span>
                      <span class="name">{{ nameSurname }}</span>
                      <span class="arrow"
                        ><i class="la la-angle-down"></i
                      ></span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a
                        style="cursor: pointer"
                        @click="logout"
                        class="dropdown-item logout"
                      >
                        <i class="la la-sign-out"></i> Çıkış Yap
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="accounts mt-5 mb-80">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="buy-sell-widget">
                  <div class="tab-content tab-content-default">
                    <div
                      class="tab-pane fade show active"
                      id="buy"
                      role="tabpanel"
                    >
                      <form class="currency_validate">
                        <div class="form-group">
                          <label class="mr-sm-2">Para Birimi</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <label class="input-group-text"
                                ><i class="cc BTC-alt"></i
                              ></label>
                            </div>
                            <select
                              name="currency"
                              v-model="ParaBirimi"
                              class="form-control"
                            >
                              <option value="TL">Türk Lirası</option>
                              <option value="EURO">Euro</option>
                              <option value="DOLLAR">Dolar</option>
                            </select>
                            <select v-model="Tip" class="form-control">
                              <option value="Gelir">Gelir</option>
                              <option value="Gider">Gider</option>
                              <option value="Gelecek">Gelecek</option>
                              <option value="Gidecek">Gidecek</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="mr-sm-2"
                            >Miktar / Tarih
                            <span
                              v-if="ToplamMiktar !== null"
                              class="text-primary"
                              >( KDV Dahil Toplam Miktar
                              <span class="miktar">{{ ToplamMiktar }}</span>
                              )</span
                            ></label
                          >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control miktar"
                              placeholder="1.000"
                              id="miktar"
                              @change="(e) => (Miktar = e.target.value)"
                            />
                            <select v-model="KDV" class="form-control">
                              <option value="Yok">KDV Yok</option>
                              <option value="18">%18</option>
                              <option value="20">%20</option>
                            </select>
                            <input
                              type="date"
                              name="usd_amount"
                              class="form-control"
                              placeholder="Tarih"
                              v-model="Tarih"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label v-if="this.OncedenYuklenenDosya"
                            >Yüklenmiş Dosya için
                            <a target="_blank" :href="'/api/' + this.OncedenYuklenenDosya"
                              >tıklayınız.</a
                            ></label
                          >
                          <div class="input-group">
                            <input
                              type="file"
                              name="file"
                              class="form-control"
                              placeholder="Dosya seçin"
                              style="padding: 9px"
                              @change="
                                filesChange(
                                  $event.target.name,
                                  $event.target.files
                                )
                              "
                            />
                            <select
                              v-model="OdemeKimeYapildi"
                              class="form-control"
                            >
                              <option value="null">Ödeme Kime Yapıldı?</option>
                              <option value="Gökben">Gökben'e Ödeme</option>
                              <option value="Ortak">Ortak Ödeme</option>
                              <option value="Mert">Nazım Mert'e Ödeme</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="mr-sm-2">Not</label>
                          <div class="input-group">
                            <textarea
                              id="not"
                              class="form-control"
                              style="padding: 10px"
                              rows="3"
                              v-model="Not"
                            ></textarea>
                          </div>
                        </div>

                        <button
                          type="button"
                          name="submit"
                          class="btn btn-success btn-block"
                          @click="save"
                        >
                          Kaydet
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-md-6">
            <div class="copy_right">
              Bu yazılım, Vue.js, Nest.js ve LowDB ile oluşturuldu.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-env jquery */

export default {
  name: "GelirGiderUpdate",
  data: () => {
    const username = localStorage.getItem("username");
    if (username === null || username === undefined || username === "") {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    return {
      Id: "",
      list: null,
      nameSurname: username === "nmb" ? "Nazım Mert Bilgi" : "Gökben Baysan",
      Tip: "",
      ParaBirimi: "",
      ToplamMiktar: null,
      Miktar: null,
      KDV: "",
      Tarih: null,
      OncedenYuklenenDosya: false,
      Dosya: null,
      OdemeKimeYapildi: null,
      Not: "",
      GenelDurum: null,
    };
  },
  methods: {
    getById(Id) {
      const apiUrl = process.env.VUE_APP_API_URL;
      fetch(apiUrl + "/get-gelir-gider/" + Id, { cache: "no-cache" })
        .then((res) => res.json())
        .then((res) => {
          this.Tip = res.Tip;
          this.ParaBirimi = res.ParaBirimi;
          this.Miktar = res.Miktar;
          this.KDV = res.KDV;
          if (this.KDV !== undefined && this.KDV !== "Yok") {
            this.Miktar = res.NetMiktar;
            this.ToplamMiktar = res.Miktar;
            setTimeout(() => {
              $(".miktar").mask("#.##0", { reverse: true });
            }, 500);
          }
          this.Tarih = res.Tarih;
          if (res.Dosya !== undefined && res.Dosya !== null) {
            this.OncedenYuklenenDosya = res.Dosya;
          }
          this.OdemeKimeYapildi = res.OdemeKimeYapildi === undefined ? this.OdemeKimeYapildi : res.OdemeKimeYapildi;
          
          this.Not = res.Not;
          $("#miktar").val(this.Miktar);
          this.init();
        });
    },
    save() {
      if (
        this.Tip === "" ||
        this.ParaBirimi === "" ||
        this.Miktar === null ||
        this.Miktar === "" ||
        this.KDV === "" ||
        this.Tarih === null ||
        this.Tarih === ""
      ) {
        this.$toast.warning("Lütfen gerekli alanları doldurun.");
        return;
      }

      let toplamMiktar = Number(this.Miktar.toString().replace(".", ""));
      let KDV = 0;

      if (this.KDV !== "Yok") {
        const kdvHesap1 = toplamMiktar / 100;
        const kdvHesap2 = Math.round(kdvHesap1 * Number(this.KDV));
        KDV = kdvHesap2;
        toplamMiktar = toplamMiktar + KDV;
      }
      const apiUrl = process.env.VUE_APP_API_URL;
      const data = {
        Id: this.Id,
        Tip: this.Tip,
        ParaBirimi: this.ParaBirimi,
        NetMiktar: Number(this.Miktar.toString().replace(".", "")),
        Miktar: Number(toplamMiktar),
        KDVMiktar: KDV,
        KDV: this.KDV,
        Tarih: this.Tarih,
        Dosya: this.Dosya,
        OdemeKimeYapildi: this.OdemeKimeYapildi,
        Not: this.Not,
        ListeAktiflik: true,
        Aktif: true,
      };
      fetch(apiUrl + "/update-gelir-gider", {
        cache: "no-cache",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            this.$toast.success("Başarılı bir şekilde güncellendi.");
            window.history.back();
          }
        });
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) {
        this.Dosya = null;
        return;
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.getBase64(fileList[x]);
      });
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.Dosya = reader.result;
      };
      reader.onerror = () => {
        this.Dosya = null;
      };
    },
    init() {
      $(".miktar").mask("#.##0", { reverse: true });
      $(".miktar2").mask("#.##0,00", { reverse: true });
    },
  },
  mounted() {
    this.Id = this.$route.params.id;
    this.getById(this.Id);
  },
};
</script>

<style>
</style>