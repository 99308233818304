<template>
  <div id="main-wrapper">
    <div class="header dashboard">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <nav class="navbar navbar-expand-lg navbar-light px-0">
              <a class="navbar-brand" href="/home"
                ><img src="images/w_logo.png" alt=""
              /></a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      style="
                        color: white !important;
                        opacity: 1 !important;
                        font-size: x-large;
                      "
                      class="nav-link"
                      href="/home"
                      >Kirpi Yazılım</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dashboard_log my-2">
                <div class="d-flex align-items-center">
                  <div class="account_money">
                    <ul>
                      <li class="crypto">
                        <span
                          ><span v-if="GenelDurum !== null" class="miktar2">{{
                            GenelDurum?.tumGelirToplamTL
                          }}</span
                          >TL</span
                        >
                      </li>
                      <li class="usd">
                        <span
                          ><span v-if="GenelDurum !== null" class="miktar2">{{
                            GenelDurum?.tumGiderToplamTL
                          }}</span>
                          TL</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="profile_log dropdown">
                    <div class="user" data-toggle="dropdown">
                      <span class="thumb"><i class="la la-user"></i></span>
                      <span class="name">{{ nameSurname }}</span>
                      <span class="arrow"
                        ><i class="la la-angle-down"></i
                      ></span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a
                        style="cursor: pointer"
                        @click="goToCalendarPage"
                        class="dropdown-item"
                      >
                        <i class="la la-sign-out"></i> Takvim
                      </a>
                      <a
                        style="cursor: pointer"
                        @click="logout"
                        class="dropdown-item logout"
                      >
                        <i class="la la-sign-out"></i> Çıkış Yap
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="accounts mt-5 mb-80">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="card acc_balance">
              <div class="card-header">
                <h4 class="card-title">Kasa</h4>
              </div>
              <div class="card-body">
                <span>Güncel Miktar</span>
                <h3>
                  <span v-if="GenelDurum !== null" class="miktar2">{{
                    GenelDurum?.guncelTL
                  }}</span>
                  Türk Lirası
                </h3>
                <hr />
                <h3>
                  <span v-if="GenelDurum !== null" class="miktar2">{{
                    GenelDurum?.guncelEURO
                  }}</span>
                  Euro
                </h3>
                <hr />
                <h3>
                  <span v-if="GenelDurum !== null" class="miktar2">{{
                    GenelDurum?.guncelDOLLAR
                  }}</span>
                  Dolar
                </h3>

                <div class="d-flex justify-content-between my-4">
                  <div>
                    <p class="mb-1">Bu ay'ki Gelirler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelirTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelirEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelirDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div>
                  <div>
                    <p class="mb-1">Bu ay'ki Giderler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGiderTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGiderEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGiderDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div>
                  <div>
                    <p class="mb-1">Bu ay'ki KDV Miktarı</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyKdvTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyKdvEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyKdvDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div>
                </div>
                <div class="d-flex justify-content-between my-4 mt-5">
                  <div>
                    <p class="mb-1">Bu ay'ki Gelecekler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelecekTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelecekEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGelecekDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div>
                  <div >
                    <p class="mb-1">Bu yıl'ki Gelecekler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buYilGelecekTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buYilGelecekEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buYilGelecekDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div>
                  <!-- <div style="margin-left: 38px">
                    <p class="mb-1">Bu ay'ki Gidecekler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGidecekTL
                      }}</span>
                      TL
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGidecekEURO
                      }}</span>
                      Euro
                    </h4>
                    <hr />
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.buAyGidecekDOLLAR
                      }}</span>
                      Dolar
                    </h4>
                  </div> -->
                </div>

                <div class="d-flex d-flex justify-content-between my-4 mt-5">
                  <div>
                    <p class="mb-1">Gökben'e Yapılan Ödemeler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.gokbeneYapilanOdeme
                      }}</span>
                      TL
                    </h4>
                    <hr />
                  </div>
                  <div>
                    <p class="mb-1">Ortak Yapılan Ödemeler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.ortakYapilanOdeme
                      }}</span>
                      TL
                    </h4>
                    <hr />
                  </div>
                  <div>
                    <p class="mb-1">Nazım Mert'e Yapılan Ödemeler</p>
                    <h4>
                      <span v-if="GenelDurum !== null" class="miktar2">{{
                        GenelDurum?.nazimMerteYapilanOdeme
                      }}</span>
                      TL
                    </h4>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="buy-sell-widget">
                  <ul class="nav nav-tabs" style="display: none">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        @click="tipDegistir('Gelir')"
                        data-toggle="tab"
                        href="#buy"
                        >Gelir</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        @click="tipDegistir('Gider')"
                        data-toggle="tab"
                        href="#buy"
                        >Gider</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content tab-content-default">
                    <div
                      class="tab-pane fade show active"
                      id="buy"
                      role="tabpanel"
                    >
                      <form class="currency_validate">
                        <div class="form-group">
                          <label class="mr-sm-2">Para Birimi</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <label class="input-group-text"
                                ><i class="cc BTC-alt"></i
                              ></label>
                            </div>
                            <select
                              name="currency"
                              v-model="ParaBirimi"
                              class="form-control"
                            >
                              <option value="TL">Türk Lirası</option>
                              <option value="EURO">Euro</option>
                              <option value="DOLLAR">Dolar</option>
                            </select>
                            <select v-model="Tip" class="form-control">
                              <option value="Gelir">Gelir</option>
                              <option value="Gider">Gider</option>
                              <option value="Gelecek">Gelecek</option>
                              <option value="Gidecek">Gidecek</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="mr-sm-2">Miktar / Tarih</label>
                          <div class="input-group">
                            <input
                              type="text"
                              id="miktar"
                              class="form-control miktar"
                              placeholder="1.000"
                              @change="(e) => (Miktar = e.target.value)"
                            />
                            <select v-model="KDV" class="form-control">
                              <option value="Yok">KDV Yok</option>
                              <option value="18">%18</option>
                              <option value="20">%20</option>
                            </select>
                            <input
                              type="date"
                              name="usd_amount"
                              class="form-control"
                              placeholder="Tarih"
                              v-model="Tarih"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group">
                            <input
                              type="file"
                              id="file"
                              class="form-control"
                              placeholder="Dosya seçin"
                              style="padding: 9px"
                              @change="
                                filesChange(
                                  $event.target.name,
                                  $event.target.files
                                )
                              "
                            />
                            <select
                              v-model="OdemeKimeYapildi"
                              class="form-control"
                            >
                              <option value="null">Ödeme Kime Yapıldı?</option>
                              <option value="Gökben">Gökben'e Ödeme</option>
                              <option value="Ortak">Ortak Ödeme</option>
                              <option value="Mert">Nazım Mert'e Ödeme</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="mr-sm-2">Not</label>
                          <div class="input-group">
                            <textarea
                              id="not"
                              class="form-control"
                              style="padding: 10px"
                              rows="3"
                              v-model="Not"
                            ></textarea>
                          </div>
                        </div>

                        <button
                          type="button"
                          name="submit"
                          class="btn btn-success btn-block"
                          @click="save"
                        >
                          Kaydet
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header border-0">
                <h4 class="card-title">Hareketler</h4>
              </div>
              <div class="card-body pt-0">
                <div class="transaction-table">
                  <div class="table-responsive">
                    <table class="table mb-0 table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Tip</th>
                          <th>Tarih</th>
                          <th>Miktar</th>
                          <th>KDV</th>
                          <th>Net</th>
                          <th>Not</th>
                          <th>Ekleyen</th>
                          <th>İşlemler</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in list" :key="item.Id">
                          <!-- <td>
                            <span v-if="item.Tip === 'Gider'" class="sold-thumb"
                              ><i class="la la-arrow-down"></i
                            ></span>
                            <span v-if="item.Tip === 'Gelir'" class="buy-thumb"
                              ><i class="la la-arrow-up"></i
                            ></span>
                          </td> -->

                          <td>
                            <span
                              class="badge"
                              v-bind:class="{
                                'badge-danger': item.Tip === 'Gider',
                                'badge-success': item.Tip === 'Gelir',
                                'badge-primary': item.Tip === 'Gelecek',
                                'badge-warning': item.Tip === 'Gidecek',
                              }"
                              >{{ item.Tip }}</span
                            >
                          </td>
                          <td>
                            <span style="display: none">{{
                              dateFormatUnix(item.Tarih)
                            }}</span
                            >{{ dateFormat(item.Tarih) }}
                          </td>
                          <td
                            v-bind:class="{
                              'text-danger': item.Tip === 'Gider',
                              'text-success': item.Tip === 'Gelir',
                              'text-primary': item.Tip === 'Gelecek',
                              'text-warning': item.Tip === 'Gidecek',
                            }"
                          >
                            <span class="miktar">{{ item.Miktar }}</span>
                            {{ item.ParaBirimi }}
                          </td>
                          <td class="text-danger">
                            <span
                              v-if="
                                item.KDVMiktar !== undefined &&
                                item.KDVMiktar !== 0
                              "
                              class="miktar"
                              >{{ item.KDVMiktar }}</span
                            >
                            {{
                              item.KDVMiktar !== undefined &&
                              item.KDVMiktar !== 0
                                ? item.ParaBirimi
                                : ""
                            }}
                          </td>
                          <td
                            v-bind:class="{
                              'text-danger': item.Tip === 'Gider',
                              'text-success': item.Tip === 'Gelir',
                              'text-primary': item.Tip === 'Gelecek',
                              'text-warning': item.Tip === 'Gidecek',
                            }"
                          >
                            <span class="miktar">{{ item.NetMiktar }}</span>
                            {{
                              item.NetMiktar !== undefined &&
                              item.NetMiktar !== 0
                                ? item.ParaBirimi
                                : ""
                            }}
                          </td>
                          <td :title="item.Not">
                            {{
                              item.Not.length > 30
                                ? item.Not.substr(0, 30) + "..."
                                : item.Not
                            }}
                          </td>
                          <td>
                            {{
                              item.Ekleyen === "Gökben Baysan" ? "G.B" : "N.M.B"
                            }}
                          </td>
                          <td>
                            <span
                              class="buy-thumb"
                              style="cursor: pointer; float: left"
                              @click="update(item.Id)"
                              title="Düzenle"
                            >
                              <i class="la la-edit"></i>
                            </span>
                            <span
                              class="sold-thumb"
                              style="cursor: pointer; float: right"
                              @click="remove(item.Id)"
                              title="Sil"
                              ><i class="la la-times-circle"></i
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-md-6">
            <div class="copy_right">
              Bu yazılım, Vue.js, Nest.js ve LowDB ile oluşturuldu.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-env jquery */

import * as moment from "moment";
import Swal from "sweetalert2";

var table = null;

export default {
  name: "Home",
  data: () => {
    const username = localStorage.getItem("username");
    if (username === null || username === undefined || username === "") {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    return {
      list: null,
      nameSurname: username === "nmb" ? "Nazım Mert Bilgi" : "Gökben Baysan",
      Tip: "Gelir",
      ParaBirimi: "TL",
      Miktar: null,
      KDV: "Yok",
      Tarih: null,
      Dosya: null,
      OdemeKimeYapildi: null,
      Not: "",
      GenelDurum: null,
    };
  },
  methods: {
    tipDegistir(val) {
      this.Tip = val;
    },
    save() {
      if (
        this.Tip === "" ||
        this.ParaBirimi === "" ||
        this.Miktar === null ||
        this.Miktar === "" ||
        this.KDV === "" ||
        this.Tarih === null ||
        this.Tarih === ""
      ) {
        this.$toast.warning("Lütfen gerekli alanları doldurun.");
        return;
      }
      let toplamMiktar = Number(this.Miktar.replace(".", ""));
      let KDV = 0;

      if (this.KDV !== "Yok") {
        const kdvHesap1 = toplamMiktar / 100;
        const kdvHesap2 = Math.round(kdvHesap1 * Number(this.KDV));
        KDV = kdvHesap2;
        toplamMiktar = toplamMiktar + KDV;
      }
      const apiUrl = process.env.VUE_APP_API_URL;
      const data = {
        Tip: this.Tip,
        ParaBirimi: this.ParaBirimi,
        NetMiktar: Number(this.Miktar.replace(".", "")),
        Miktar: Number(toplamMiktar),
        KDVMiktar: KDV,
        KDV: this.KDV,
        Tarih: this.Tarih,
        Dosya: this.Dosya,
        OdemeKimeYapildi: this.OdemeKimeYapildi,
        Not: this.Not,
        Ekleyen: this.nameSurname,
        ListeAktiflik: true,
        Aktif: true,
      };
      fetch(apiUrl + "/add-gelir-gider", {
        cache: "no-cache",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            this.$toast.success("Başarılı bir şekilde kaydedildi.");
            table.destroy();
            table = null;
            this.clearForm();
            this.getList();
            this.genelDurum();
          }
          else{
             this.$toast.error("Hata oluştu. Lütfen teknik desteğe haber veriniz.");
          }
        });
    },
    clearForm() {
      this.Miktar = null;
      this.Dosya = null;
      this.Tarih = null;
      this.OdemeKimeYapildi = null;
      this.Not = "";
      document.getElementById("miktar").value = null;
      document.getElementById("file").value = null;
    },
    remove(Id) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu işlemin geri dönüşü yoktur. ( belki de vardır :P )",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, sil",
        cancelButtonText: "Hayır, silme",
      }).then((result) => {
        if (result.value) {
          const apiUrl = process.env.VUE_APP_API_URL;
          const data = {
            Id,
          };
          fetch(apiUrl + "/remove-gelir-gider", {
            cache: "no-cache",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res) {
                this.$toast.success("Başarılı bir şekilde silindi.");
                table.destroy();
                table = null;
                this.getList();
                this.genelDurum();
              }
            });
        }
      });
    },
    update(Id) {
      window.location.href = "/update/" + Id;
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.yyyy");
      }
    },
    dateFormatUnix(value) {
      if (value) {
        return moment(String(value)).unix();
      }
    },
    getList() {
      const apiUrl = process.env.VUE_APP_API_URL;
      fetch(apiUrl + "/list-gelir-gider", { cache: "no-cache" })
        .then((res) => res.json())
        .then((res) => {
          this.list = res;
          setTimeout(() => {
            if (table === null) {
              this.init();
              table = $(".table").DataTable({
                ordering: true,
                searching: true,
                order: [[1, "desc"]],
              });
            }
          }, 500);
        });
    },
    genelDurum() {
      const apiUrl = process.env.VUE_APP_API_URL;
      fetch(apiUrl + "/genel-durum", { cache: "no-cache" })
        .then((res) => res.json())
        .then((res) => {
          res = {
            buAyGelirDOLLAR: Number(res.buAyGelirDOLLAR).toFixed(2),
            buAyGelecekDOLLAR: Number(res.buAyGelecekDOLLAR).toFixed(2),
            buYilGelecekDOLLAR:Number(res.buYilGelecekDOLLAR).toFixed(2),
            buAyGelirEURO: Number(res.buAyGelirEURO).toFixed(2),
            buAyGelecekEURO: Number(res.buAyGelecekEURO).toFixed(2),
            buYilGelecekEURO:Number(res.buYilGelecekEURO).toFixed(2),
            buAyGelirTL: Number(res.buAyGelirTL).toFixed(2),
            buAyGelecekTL: Number(res.buAyGelecekTL).toFixed(2),
            buYilGelecekTL:Number(res.buYilGelecekTL).toFixed(2),
            buAyGiderDOLLAR: Number(res.buAyGiderDOLLAR).toFixed(2),
            buAyGidecekDOLLAR: Number(res.buAyGidecekDOLLAR).toFixed(2),
            buAyGiderEURO: Number(res.buAyGiderEURO).toFixed(2),
            buAyGidecekEURO: Number(res.buAyGidecekEURO).toFixed(2),
            buAyGiderTL: Number(res.buAyGiderTL).toFixed(2),
            buAyGidecekTL: Number(res.buAyGidecekTL).toFixed(2),
            guncelDOLLAR: Number(res.guncelDOLLAR).toFixed(2),
            guncelEURO: Number(res.guncelEURO).toFixed(2),
            guncelTL: Number(res.guncelTL).toFixed(2),
            buAyKdvTL: Number(res.buAyKdvTL).toFixed(2),
            buAyKdvEURO: Number(res.buAyKdvEURO).toFixed(2),
            buAyKdvDOLLAR: Number(res.buAyKdvDOLLAR).toFixed(2),
            tumGelirToplamTL: Number(res.tumGelirToplamTL).toFixed(2),
            tumGiderToplamTL: Number(res.tumGiderToplamTL).toFixed(2),
            gokbeneYapilanOdeme: Number(res.gokbeneYapilanOdeme).toFixed(2),
            ortakYapilanOdeme: Number(res.ortakYapilanOdeme).toFixed(2),
            nazimMerteYapilanOdeme: Number(res.nazimMerteYapilanOdeme).toFixed(
              2
            ),
          };
          this.GenelDurum = res;
          this.init();
        });
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) {
        this.Dosya = null;
        return;
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.getBase64(fileList[x]);
      });
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.Dosya = reader.result;
      };
      reader.onerror = () => {
        this.Dosya = null;
      };
    },
    logout() {
      localStorage.clear();
      window.location.href = "/";
      return;
    },
    goToCalendarPage(){
      window.location.href = "/calendar";
      return;
    },
    init() {
      $(".miktar").mask("#.##0", {
        reverse: true,
        translation: {
          "#": {
            pattern: /-|\d/,
            recursive: true,
          },
        },
        onChange: function (value, e) {
          e.target.value = value
            .replace(/(?!^)-/g, "")
            .replace(/^(-[,.])/, "-")
            .replace(/(\d+\.*)\.(\d{2})$/, "$1,$2");
        },
      });
      $(".miktar2").mask("#.##0,00", {
        reverse: true,
        translation: {
          "#": {
            pattern: /-|\d/,
            recursive: true,
          },
        },
        onChange: function (value, e) {
          e.target.value = value
            .replace(/(?!^)-/g, "")
            .replace(/^(-[,.])/, "-")
            .replace(/(\d+\.*)\.(\d{2})$/, "$1,$2");
        },
      });
    },
  },
  mounted() {
    this.init();
    this.getList();
    this.genelDurum();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
