
<template>
  <div id="calendar"></div>
</template>

<script>
/* eslint-disable no-undef */

var currentYear = new Date().getFullYear();

export default {
  name: "Calendar",
  mounted() {
    new Calendar("#calendar", {
      style: "background",
      language: 'tr',
      minDate:new Date(currentYear, 0, 1),
      maxDate:new Date(currentYear, 11, 31),
      mouseOnDay: function (e) {
        if (e.events.length > 0) {
          var content = "";

          for (var i in e.events) {
            content +=
              '<div class="event-tooltip-content">' +
              '<div class="event-name" style="color:' +
              e.events[i].color +
              '">' +
              e.events[i].name +
              "</div>" +
              '<div class="event-location">' +
              e.events[i].location +
              "</div>" +
              "</div>";
          }

          $(e.element).popover({
            trigger: "manual",
            container: "body",
            html: true,
            content: content,
          });

          $(e.element).popover("show");
        }
      },
      mouseOutDay: function (e) {
        if (e.events.length > 0) {
          $(e.element).popover("hide");
        }
      },
      dayContextMenu: function (e) {
        $(e.element).popover("hide");
      },
      dataSource: [
        {
          id: 0,
          name: "Karayol Lastik",
          location: "250 Dolar",
          startDate: new Date(currentYear, 0, 25),
          endDate: new Date(currentYear, 0, 25),
        },
        {
          id: 1,
          name: "Golf Club",
          location: "250 Dolar",
          startDate: new Date(currentYear, 0, 3),
          endDate: new Date(currentYear, 0, 3),
        },
        {
          id: 2,
          name: "Emre Lastik",
          location: "1300 Dolar",
          startDate: new Date(currentYear, 9, 11),
          endDate: new Date(currentYear, 9, 11),
        },
        {
          id: 3,
          name: "Petlas LYS",
          location: "1600 Dolar",
          startDate: new Date(currentYear, 10, 22),
          endDate: new Date(currentYear, 10, 22),
        },
        {
          id: 4,
          name: "Bekamer",
          location: "9500 TL",
          startDate: new Date(currentYear, 2, 30),
          endDate: new Date(currentYear, 2, 30),
        },
        {
          id: 5,
          name: "Tatko1927 Karkas",
          location: "1800 Dolar",
          startDate: new Date(currentYear, 6, 6),
          endDate: new Date(currentYear, 6, 6),
        },
        {
          id: 6,
          name: "Tatko1927 LYS",
          location: "2000 Dolar",
          startDate: new Date(currentYear, 1, 5),
          endDate: new Date(currentYear, 1, 5),
        },
        {
          id: 7,
          name: "Hankook EUC",
          location: "2310 EURO",
          startDate: new Date(currentYear, 10, 30),
          endDate: new Date(currentYear, 10, 30),
        },
        {
          id: 8,
          name: "Hankook Freigaben",
          location: "2420 EURO",
          startDate: new Date(currentYear, 10, 30),
          endDate: new Date(currentYear, 10, 30),
        },
        {
          id: 9,
          name: "Ataser",
          location: "350 Dolar - (Konuşulacak)",
          startDate: new Date(currentYear, 0, 18),
          endDate: new Date(currentYear, 0, 18),
        },
        
      ],
    });
  },
};

/* eslint-disable no-undef */
</script>