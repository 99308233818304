<template>
  <div id="main-wrapper">
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <nav
              class="navbar navbar-expand-lg navbar-light px-0 justify-content-center"
            >
              <a class="navbar-brand" href="index.html"
                ><img src="images/w_logo.png" alt="" />
                <span>Kirpi Yazılım</span></a
              >
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-4 col-md-6">
            <div class="auth-form card">
              <div class="card-header justify-content-center">
                <h4 class="card-title">Giriş Yap</h4>
              </div>
              <div class="card-body">
                <form class="signin_validate">
                  <div class="form-group">
                    <label>Kullanıcı Adı</label>
                    <input
                      type="text"
                      v-model="username"
                      class="form-control"
                      placeholder="Kullanıcı Adı"
                      name="username"
                    />
                  </div>
                  <div class="form-group">
                    <label>Şifre</label>
                    <input
                      type="password"
                      v-model="password"
                      class="form-control"
                      placeholder="Şifre"
                      name="password"
                    />
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      @click="login"
                      class="btn btn-success btn-block"
                    >
                      Giriş Yap
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg_icons"></div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    const username = localStorage.getItem("username");
    if (username !== null && username !== undefined && username !== "") {
      window.location.href = "/home";
      return;
    }
    return {
      username: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    login() {
      if (this.username === "" || this.password === "") {
        this.$toast.error(`Lütfen kullanıcı adı ve şifre kısmını doldurunuz.`);
      } else if (this.username === "nmb" && this.password === "1998") {
        localStorage.setItem("username", this.username);
        this.$toast.success(
          "Sayın Nazım Mert Bilgi, başarıyla giriş yaptınız.."
        );
        setTimeout(() => {
          this.$router.go("home");
        }, 1500);
      } else if (this.username === "gokben" && this.password === "kokoben") {
        localStorage.setItem("username", this.username);
        this.$toast.success("Sayın Gökben Baysan, başarıyla giriş yaptınız..");
        setTimeout(() => {
          this.$router.go("home");
        }, 1500);
      } else {
        this.$toast.error(
          `Lütfen kullanıcı adı ve şifrenizi kontrol ederek tekrar giriniz.`
        );
      }
      setTimeout(this.$toast.clear, 2000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
